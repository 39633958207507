@import "../scss/vars.scss";
@import "./typography.scss";

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}
a:focus {
  outline: none;
}

body {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 16px;
  color: $textblack;
  background-color: $white;
  position: relative;
  width: 100%;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.StripeElement {
  margin-top: 20px;
  padding: 16px 20px;
  border: 1px solid $grayE4;
  border-radius: 14px;
  height: 56px;
}

.StripeElement--invalid {
  border: 1px solid red;
  border-radius: 10px;
  height: 56px;
}

.html-wrapper {
  & p {
    @include text_medium_regular;

    & strong {
      color: $textblack;
      @include text_medium_bold;
    }
  }

  & ul {
    margin-left: 40px;
    & li {
      list-style: disc;
      @include text_medium_regular;
      & strong {
        color: $textblack;
        @include text_medium_bold;
      }
    }
  }

  & ol {
    margin-left: 40px;

    & li {
      list-style: decimal;
      @include text_medium_regular;
      & strong {
        color: $textblack;
        @include text_medium_bold;
      }
    }
  }

  & .ql-align-center {
    text-align: center;
  }

  & .ql-align-right {
    text-align: right;
  }

  & .ql-align-justify {
    text-align: justify;
  }

  & em {
    font-style: italic;
  }

  & .ql-indent-1 {
    text-indent: 20px;
  }

  & .ql-indent-2 {
    text-indent: 40px;
  }

  & .ql-indent-3 {
    text-indent: 60px;
  }

  & .ql-indent-4 {
    text-indent: 80px;
  }

  & .ql-indent-5 {
    text-indent: 100px;
  }

  & .ql-indent-6 {
    text-indent: 120px;
  }

  & .ql-indent-7 {
    text-indent: 140px;
  }

  & .ql-indent-8 {
    text-indent: 160px;
  }

  & .ql-size-small {
    @include text_small_regular;
  }

  & .ql-size-large {
    @include text_medium_regular;
  }

  & .ql-size-huge {
    @include text_large_regular;
  }

  & h1 {
    @include desktop_h1;

    @media (max-width: 768px) {
      @include mobile_h1;
    }
  }
  & h2 {
    @include desktop_h2;

    @media (max-width: 768px) {
      @include mobile_h2;
    }
  }

  & h3 {
    @include desktop_h3;

    @media (max-width: 768px) {
      @include mobile_h3;
    }
  }

  & h4 {
    @include desktop_h4;

    @media (max-width: 768px) {
      @include mobile_h4;
    }
  }
  & h5 {
    @include desktop_h5;

    @media (max-width: 768px) {
      @include mobile_h5;
    }
  }

  & a {
    @include text_small_regular;
    text-decoration: underline;
    color: $textblack;
  }

  & img {
    width: 100%;
    height: auto;
    border-radius: 30px;
    object-fit: cover;

    @media (max-width: 768px) {
      height: auto;
      border-radius: 20px;
    }
  }

  & .ql-video {
    width: 100%;
    height: 400px;

    @media (max-width: 768px) {
      height: 350px;
    }
    @media (max-width: 500px) {
      height: 200px;
    }
    @media (max-width: 360px) {
      height: 180px;
    }
  }
}

.sliderWrapper {
  :global(.swiper-pagination-bullet-active) {
    background-color: #8c42e3;
  }
}
