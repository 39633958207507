@import "../../scss/main.scss";

.fasting {
  width: 30px;
  height: 30px;
}

.wrapper {
  max-width: 1560px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 92vh;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    max-width: 768px;
    flex-direction: column;
    justify-content: baseline;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
    height: 100%;
  }

  @media (max-width: 1440px) {
    max-width: 1120px;
  }

  & .discountsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-top: 30px;

    @media (max-width: 768px) {
      // overflow: hidden;
      width: 100%;
      padding-top: 0px;
      margin-top: -40px;
    }

    @media (max-width: 1440px) {
      margin-top: 15px;
    }

    &Active {
      z-index: 999999;
      position: relative;
      // background: white;
      border-radius: 40px;
      align-self: flex-start;

      & > div:not(:first-child) {
        pointer-events: none;
      }
    }

    &Tooltip {
      right: 0;
      top: 50%;
      transform: translate(97%, -40%);
      &::after {
        left: -8px;
        rotate: 0deg;
        top: 50%;
        transform: translateY(50%);
      }

      @media (max-width: 1440px) {
        left: 0;
        top: 50%;
        transform: translate(-97%, -40%);
        &::after {
          left: initial;
          right: -8px;
          rotate: 180deg;
          top: 50%;
          transform: translateY(50%);
        }
      }
      @media (max-width: 768px) {
        left: 50%;
        top: 0;
        transform: translate(-50%, -97%);
        &::after {
          left: 50%;
          rotate: 270deg;
          top: initial;
          bottom: -16px;
          transform: translateX(50%);
        }
      }
    }
  }

  & .boostcampsList {
    @media (max-width: 1440px) {
      flex: 1;
    }

    padding-top: 30px;
    @media (max-width: 768px) {
      max-width: 768px;
      padding-top: 20px;
    }

    & .loaderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 200px;

      @media (max-width: 768px) {
        margin-top: 100px;
        margin-bottom: 100px;
      }
    }
  }

  & .dailyTaskList {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 422px;
    overflow: visible;
    padding-top: 30px;

    @media (max-width: 768px) {
      display: none;
      overflow: hidden;
      padding-top: 0px;
      padding-bottom: 10px;
    }

    @media (max-width: 1440px) {
      // overflow-y: scroll;
      padding-bottom: 30px;
      width: 462px;
      padding-left: 30px;
    }

    &Active {
      position: relative;
    }

    &Container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 30px;
      padding: 30px;
      background-color: #f9f2d0;
      align-self: flex-start;

      &Active {
        z-index: 999999;
        position: relative;
        // background: white;
        // border-radius: 30px;

        & > div:not(:first-child) {
          pointer-events: none;
        }
      }
    }

    &Tooltip {
      left: 0;
      top: 50%;
      transform: translate(-97%, -75%);

      &::after {
        right: -8px;
        rotate: 180deg;
        top: 50%;
        transform: translateY(50%);
      }
    }

    &Title {
      @include desktop_h3;

      @media (max-width: 768px) {
        @include mobile_h3;
        margin-bottom: 15px;
      }
    }
  }
}

.stickyMenu {
  display: flex;
  width: 56px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 40px;
  margin-right: auto;
  margin-left: 16px;
  z-index: 99999;
}

.boostPreview {
  &Active {
    z-index: 999999;
    position: relative;
    background: white;
    border-radius: 40px;
    padding: 20px 20px 20px 0;
    margin: -20px -20px -20px 0;

    & > div:first-child {
      pointer-events: none;
    }
  }

  &Tooltip {
    right: 0;
    top: 50%;
    transform: translate(95%, -50%);

    &::after {
      left: -8px;
      rotate: 0deg;
      top: 50%;
      transform: translateY(50%);
    }

    @media (max-width: 768px) {
      left: 50%;
      top: initial;
      bottom: 0;
      transform: translate(-50%, 97%);
      &::after {
        left: initial;
        right: 50%;
        rotate: 90deg;
        top: -17px;
        transform: translateX(50%);
      }
    }
  }
}
