@import "../../scss/main.scss";

.wrapper {
  max-width: 625px;
  width: 100%;
  padding: 30px;
  border-radius: 30px;
  background: $white;
  margin: 30px auto;

  @media (max-width: 768px) {
    padding: 20px;
    border-radius: 20px;
    margin: 20px auto;
  }

  & h4 {
    @include desktop_h4;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;

    @media (max-width: 768px) {
      @include mobile_h4;
      margin-top: 20px;
    }
  }

  & .imgWrapper {
    display: flex;

    justify-content: center;
  }
}

.subtitle {
  @include text_small_regular;
  color: $textdark;
  text-align: center;
  margin-top: 15px;

  @media (max-width: 768px) {
    @include text_x_small_regular;
    margin-top: 10px;
  }

  & span {
    color: $primary;
    @include text_small_bold;
    @media (max-width: 768px) {
      @include text_x_small_bold;
    }
  }
}

.email {
  @include text_medium_bold;
  margin-bottom: 30px;
  text-align: center;

  @media (max-width: 768px) {
    @include text_small_bold;
    margin-bottom: 20px;
  }
}

.inputLabel {
  @include text_medium_regular;
  text-align: center;
  color: $textdark;

  @media (max-width: 768px) {
    @include text_small_regular;
  }
}

.codeInput {
  padding: 13px 20px;
  border-radius: 15px;
  border: 1px solid $grayE4;
  width: 343px;
  @include text_x_large_bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 768px) {
    @include text_large_bold;
    padding: 10px 18px;
  }

  &:focus {
    outline: none;
  }
}

.inputWrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.error {
  @include text_small_bold;
  text-align: center;
  color: $error;
  margin-top: 20px;

  @media (max-width: 768px) {
    @include text_x_small_bold;
    margin-top: 15px;
  }
}
