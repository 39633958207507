@import "../../scss/main.scss";

.container {
  width: 625px;
  margin: 30px auto;
  padding: 30px;
  background: $white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px auto;

    padding: 14px;
  }
}

.navigationBtnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    justify-content: space-between;
  }

  & a {
    @include inactiveNavLink;
    &[class*="active"] {
      @include activeNavLink;
    }
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & input {
    width: 272px;
  }
}

input {
  width: 100%;
}

.error {
  @include text_small_regular;
  color: $error;
  text-align: center;
}

.forgotWrapper {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;

  & span {
    @include text_small_medium;
    text-decoration: none;
    color: $primary;
    cursor: pointer;
  }
}

.supportWrapper {
  align-self: center;
  @include text_small_regular;

  a {
    @include text_small_medium;
    color: $primary;
  }
}
