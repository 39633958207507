@import "../../scss/main";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 0;
  border-top: 1px solid $grayE4;
}

.avatarBlock {
  word-wrap: break-word;
  width: 120px;
  margin-right: 30px;

  & .avatar {
    width: 120px;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
  }
}

.textBlock {
  display: flex;
  flex-direction: column;

  & .name {
    @include desktop_h5;
  }
  & .city {
    margin-top: 5px;
    @include text_small_regular;
    color: $gray7C;
  }

  & .ratingWrapper {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & .text {
    @include text_medium_regular;
    color: $gray3A;
  }
}

.mobileWrapper {
  // width: 310px;
  height: 100%;
  background: $white;
  border-radius: 20px;
  padding: 20px;
  // overflow: hidden;

  & .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;

    & .avatar {
      width: 90%;
      height: auto;
      aspect-ratio: 6/5;
      object-fit: cover;
      border-radius: 10px;
      max-width: 350px;
    }

    & .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      & .name {
        @include mobile_h4;
        line-height: 18px;
      }
      & .city {
        @include text_small_regular;
        color: $textdark;
      }

      & .ratingWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
      }
    }
  }
  & .text {
    @include text_medium_regular;
    color: $textdark;
    margin-top: 20px;
    word-wrap: break-word;
    // text-overflow: ellipsis;
  }
}
