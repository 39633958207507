@import "../../scss/main.scss";

.trackingTypeBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }

  & .trackingTypeBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
    gap: 10px;
    background: #f8f8f8;
    cursor: pointer;
    border-radius: 10px;

    @include desktop_h5;
    color: $grayAf;
  }

  & .trackingTypeBtnActive {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
    gap: 10px;
    background: #f8f8f8;
    border-radius: 10px;
    cursor: pointer;

    @include desktop_h5;
    color: $white;
  }
}
