@import "../../scss/main";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.blockItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time {
  @include desktop_h4;
  color: $white;

  @media (max-width: 768px) {
    @include mobile_h5;
  }
}
.title {
  @include text_small_regular;
  color: $white;

  @media (max-width: 768px) {
    @include text_x_small_regular;
  }
}
.divider {
  width: 1px;
  height: 30px;
  background: rgba(255, 255, 255, 0.2);
}
