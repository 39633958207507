@import "../../scss/main.scss";

$mobileAdapriveWidth: 950px;

.wrapper {
  margin-top: 10px;
  width: 407px;
  border-radius: 30px;
  padding: 30px 30px 40px 30px;
  // background: rgba(252, 217, 48, 0.2);
  background-color: #f9f2d0;

  @media (max-width: $mobileAdapriveWidth) {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }

  & .checklist {
    width: 100%;
    max-height: 190px;
    margin-bottom: 30px;
    display: flex;
    padding-right: 15px;
    flex-direction: column;
    gap: 15px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background: $primary;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $primary;
    }
  }

  & .title {
    @include desktop_h3;

    @media (max-width: $mobileAdapriveWidth) {
      @include mobile_h3;
      margin-bottom: 15px;
    }
  }
}

.blurWrapper {
  filter: blur(3px);
  user-select: none;
}

.blockIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  color: $primary;
}
