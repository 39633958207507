@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 15px;
  background: $white;
  box-shadow: 0px 11px 50px rgba(0, 0, 0, 0.07);
}
