@import "../../scss/main.scss";

.wrapper {
  position: absolute;
  z-index: 9999999;
  width: 315px;
  background-color: $primary;
  padding: 15px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  color: $white;
  pointer-events: all;

  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 9px 0;
    border-color: transparent $primary transparent transparent;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    width: 100%;
  }

  .steps {
    flex: 1;
    width: 100%;
  }

  .button {
    flex: 0 1;
    border-radius: 15px;
    padding: 11px 20px;
    background-color: $white;
    color: $primary;
  }

  .btns {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .skip {
    flex: 0 1;
    border-radius: 15px;
    padding: 11px 20px;
    background-color: $primary;
    color: $white;
  }

  .text {
    margin: 5px 0 12px;
  }
}
