@import "../../scss/main";

.wrapper {
  min-height: 124px;
  padding-left: 50px;
  padding-right: 50px;
  background: $grayF8;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.contentContainer {
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;

  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  border-top: 1px solid $grayE4;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 20px;
  }
}
.description {
  max-width: 70%;
  @include text_x_small_regular;
  color: $gray3A;

  @media (max-width: 900px) {
    flex: 2;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
  @media (max-width: 500px) {
    max-width: 345px;
    align-self: center;
    text-align: center;
  }
}

.supportWrapper {
  display: flex;
  align-items: center;
  margin-top: 0px;
  padding-right: 70px;

  @media (max-width: 900px) {
    flex: 1;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 500px) {
    padding: 0;
  }

  & .supportText {
    display: flex;
    justify-content: flex-start;

    @include text_x_small_regular;

    & .supportTextItem {
      color: $primary;
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;

      &:not(:last-child) {
        border-right: 1px solid $gray3A;
      }
      &:first-child {
        color: $textblack;
        cursor: initial;
        padding-left: 0;
      }

      a {
        color: $primary;
      }
      & span {
        color: $primary;
        cursor: pointer;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      text-align: center;

      .supportTextItem {
        padding: 5px 0;
        &:not(:last-child) {
          border-right: none;
        }
      }
    }
    @media (max-width: 768px) {
      flex-direction: row;
      text-align: left;

      .supportTextItem {
        padding: 0 10px;
        &:not(:last-child) {
          border-right: 1px solid $gray3A;
        }
      }
    }
    @media (max-width: 500px) {
      flex-direction: column;
      align-self: center;
      text-align: center;

      .supportTextItem {
        font-size: 14px;
        padding: 5px 0;
        &:not(:last-child) {
          border-right: none;
        }
      }
    }
  }
}
