@import "../../scss/main.scss";

.wrapper {
  max-width: 1280px;
  margin: 30px auto;
  padding: 0 50px;

  @media (max-width: 768px) {
    margin: 20px auto;
    padding: 0 16px;
  }

  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & .title {
      width: 290px;
      text-align: center;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      margin: 0 10px;

      @media (max-width: 768px) {
        @include mobile_h4;
      }
    }

    & .prevBtn {
      width: 60px;
      height: 60px;
      border: 1px solid #e4e4e4;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media (max-width: 768px) {
        border: none;
        width: auto;
        height: auto;
      }
    }
  }

  & .loader {
    display: flex;
    height: 60vh;
    align-items: center;
    justify-content: center;
  }

  & .contentContainer {
    display: flex;
    margin-top: 50px;
    gap: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 20px;
    }

    & .menuContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 298px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    & .daysContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (max-width: 768px) {
        flex-direction: row;
        width: 100%;
        justify-content: center;
        // gap: 5px;
      }

      @media (max-width: 450px) {
        gap: 5px;
      }
      @media (max-width: 375px) {
        max-width: 235px;
        flex-wrap: wrap;
        gap: 10px;
        align-self: center;
      }

      & .dayItem {
        padding: 15px 30px;
        background: #fff;
        border-radius: 30px;
        cursor: pointer;

        @media (max-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 44px;
          height: 44px;
          padding: 0;
        }

        & span {
          font-weight: 800;
          font-size: 24px;
          line-height: 28px;

          @media (max-width: 768px) {
            @include mobile_h5;
          }
        }
      }

      & .dayItemActive {
        border: 4px solid #8c42e3;
        padding: 12px 26px;

        @media (max-width: 768px) {
          padding: 0;
          border-width: 3px;
        }
      }
    }

    & .recipeContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;

      @media (max-width: 768px) {
        gap: 10px;
      }

      & .recipeItemWrapper {
        padding: 30px;
        background: #fff;
        border-radius: 30px;

        @media (max-width: 768px) {
          padding: 20px;
        }

        & .title {
          @include desktop_h4;

          @media (max-width: 768px) {
            @include mobile_h4;
          }
        }

        & .descriptionWrapper {
          background: #f8f8f8;
          padding: 20px;
          border-radius: 20px;
          margin-top: 20px;

          @media (max-width: 768px) {
            margin-top: 15px;
            padding: 13px 20px;
          }

          & p {
            @include text_large_regular;

            @media (max-width: 768px) {
              @include text_medium_regular;
            }
          }
        }
      }

      & .recipeButtonsWrapper {
        display: flex;
        gap: 15px;

        & .recipeButton {
          background: #60c055;
          border-radius: 24px;
          flex: 1;
          height: 48px;
        }
      }
    }
  }
}

.infoModal {
  height: 70vh;

  @media (max-width: 768px) {
    height: 70vh;
    padding-left: 16px;
    padding-right: 16px;
  }
  .infoModalInner {
    width: 730px;
    background: #fff;
    border-radius: 30px;
    overflow: hidden;
    position: relative;

    @media (max-width: 768px) {
      width: 100%;
    }

    & .closeBtn {
      position: absolute;
      width: 42px;
      height: 42px;
      background: rgba(19, 19, 19, 0.5);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 20px;
      right: 20px;
      z-index: 999;
      cursor: pointer;
    }

    & .infoModalWrapper {
      padding: 30px;
      background: #fff;
      border-radius: 30px;

      @media (max-width: 768px) {
        padding: 20px;
      }

      & .infoModalTitle {
        @include desktop_h4;

        @media (max-width: 768px) {
          @include mobile_h4;
        }
      }

      & .infoModalDescription {
        border-radius: 20px;
        margin-top: 20px;

        @media (max-width: 768px) {
          margin-top: 15px;
          padding: 13px 20px;
        }

        & p {
          @include text_large_regular;

          @media (max-width: 768px) {
            @include text_medium_regular;
          }
        }
      }
    }
  }
}

.infoModalOverlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
  overflow-y: auto;
}
