@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid $grayE4;

  & .iconWrapper {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    background: $primaryOpacity;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .title {
    @include text_small_bold;
    color: $textblack;
    word-break: break-all;
  }

  & .description {
    @include text_small_regular;
    color: $textdark;

    & a {
      color: $primary;
      text-decoration: underline;
    }
  }
}
