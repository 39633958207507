@import "../../scss/main.scss";

.modalOverlay {
  outline: none;
}

.contentBox {
  display: flex;
  flex-direction: column;
  height: 95vh;
  width: 670px;

  padding: 30px;
  border-radius: 30px;
  background: $white;

  @media (max-width: 768px) {
    border-radius: 20px;
    padding: 20px;
    width: auto;
    height: -webkit-fill-available;
  }

  & .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    & .crossBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.loaderWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 80vh;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.pdfView {
  width: 100%;
  height: auto;
  overflow-y: scroll;

  @media (max-width: 768px) {
    max-width: 320px;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  & .downloadBtnWrapper {
    position: absolute;
    right: 0;
    width: 210px;
    @media (max-width: 768px) {
      width: auto;
    }
  }

  & .navigationBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    width: 154px;
    background: rgba(19, 19, 19, 0.6);
    border-radius: 15px;
    @media (max-width: 768px) {
      width: auto;
    }
    & .prevBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        color: $white;
      }
      cursor: pointer;
    }

    & span {
      @include desktop_h5;
      color: $white;

      @media (max-width: 768px) {
        @include mobile_h5;
      }
    }

    & .nextBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        color: $white;
      }
      cursor: pointer;
    }
  }
}
