@import "../../scss/main.scss";

.wrapper {
  width: 625px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: $white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  padding: 30px;
  height: auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 14px;
    border-radius: 20px;
    height: auto;
  }

  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & .selectCountry {
      display: flex;
      align-items: center;
      gap: 10px;
      // border: 1px solid $grayE4;
      // padding: 12px 20px;
      border-radius: 15px;
      margin-left: auto;
      // cursor: pointer;
      position: relative;

      @media (max-width: 768px) {
        padding: 12px 14px;
      }

      & span {
        @include text_small_bold;

        @media (max-width: 768px) {
          @include text_x_small_bold;
        }
      }

      & .countryList {
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        padding: 5px;
        gap: 5px;
        top: 54px;
        left: 0;
        width: 100%;
        background: $white;
        position: absolute;
        border: 1px solid $grayE4;
        overflow: hidden;
        z-index: 1;

        & .selectCountry {
          margin-left: 0px;
        }
      }
    }
  }

  & h4 {
    @include desktop_h4;
    margin-top: 15px;

    @media (max-width: 768px) {
      @include mobile_h4;
      margin-top: 0;
    }
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.expDateWrapper {
  flex: 1;
}

.divider {
  width: 100%;
  height: 1px;
  background: $grayE4;
  margin-top: 5px;
  margin-bottom: -15px;

  @media (max-width: 768px) {
    margin-bottom: -35px;
  }
}

.boostcampItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-top: 5px;

  @media (max-width: 768px) {
    align-items: flex-start;
    padding-bottom: 20px;
    border-bottom: 1px solid $grayE4;
  }

  & .boostcampImg {
    width: 90px;
    height: 80px;
    border-radius: 15px;
    overflow: hidden;
    object-fit: cover;
  }

  & .boostcampContent {
    flex: 1;

    @media (max-width: 768px) {
    }

    & h5 {
      @include desktop_h5;
      margin-top: 3px;

      @media (max-width: 768px) {
        @include mobile_h5;
      }
    }

    & .dateTitle {
      @include text_x_small_bold;
      font-weight: 800;
      color: $primary;
      text-transform: uppercase;
    }

    & h4 {
      @include mobile_h4;
      margin-top: 5px;
    }

    & p {
      @include text_small_regular;
      margin-top: 5px;

      @media (max-width: 768px) {
        margin-top: 2px;
      }
    }
  }

  & h4 {
    @include desktop_h4;
    margin: 0;
  }
}

.checkBoxWrapper {
  border: 3px dashed $primary;
  border-radius: 20px;
  padding: 20px;

  & .checkbox_header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    & .checkbox_headerContent {
      @include desktop_h5;
      color: $primary;
      margin-top: 4px;

      @media (max-width: 768px) {
        @include mobile_h5;
      }
    }
  }

  & .checkbox_footer {
    margin-top: 10px;
    width: 100%;
    @media (max-width: 768px) {
      margin-top: 0;
    }
    & p {
      @include text_small_regular;
    }
  }
}

.startBtn {
  background: $error;
  min-height: 68px;
  max-height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  margin-top: 15px;
  text-align: center;
  @include desktop_h4;
  cursor: pointer;
  color: $white;
}

.footer {
  width: 406px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  & p {
    @include text_small_regular;
    margin-top: 20px;

    & span {
      @include text_small_bold;
    }
  }
}

.stripeBlock {
  display: flex;
  flex-direction: row;
  align-items: center;

  & p {
    @include text_small_regular;
    margin-left: 10px;
    flex: 1;
    margin-top: 0;
    & span {
      @include text_small_medium;
    }
  }
}

.paymentMehods {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.selectMondayBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $grayE4;
  border-radius: 15px;
  padding: 13px 20px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s linear;
  & svg {
    transform: rotate(0deg);
  }
}

.selectMondayBtnOpen {
  & svg {
    transform: rotate(180deg);
    transition: all 0.2s linear;
  }
}

.modayListWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  background-color: $white;
  top: 60px;
  left: 0;
  right: 0;
  padding: 10px 10px;
  border: 1px solid $grayE4;
  border-radius: 15px;

  & .selectMondayBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $grayE4;
    border-radius: 15px;
    padding: 13px 20px;
    cursor: pointer;
    position: relative;
  }

  & .selectMondayBtnActive {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $primary;
    border-radius: 15px;
    padding: 13px 20px;
    cursor: pointer;
    position: relative;

    & svg {
      color: $primary;
      transform: rotate(0deg);
    }

    & .mondayTitle {
      @include text_small_bold;
      color: $primary;
    }
  }
}

.mondayTitle {
  @include text_small_regular;
}

.selectedLabel {
  @include text_small_bold;
}

.error {
  @include text_medium_bold;
  color: $error;
  text-align: center;
  margin-top: 10px;
}
