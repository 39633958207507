@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  border: 3px solid transparent;
  padding: 7px;
  gap: 10px;
  width: 106%;

  & .iconWrapper {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
  }

  & .descriptionWrapper {
    flex: 5;
    & .value {
      @include mobile_h4;
    }

    & .title {
      @include text_small_regular;
      color: $textdark;
    }
  }

  & .shareWrapper {
    display: flex;
    align-items: center;
    gap: 7px;

    flex: 1;

    & .shareTitle {
      @include text_x_small_regular;
      color: $grayAf;
      margin-left: 7px;
    }
  }
}

.graphContainer {
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;

  .graphContainerInner {
    width: 600px;
  }
}
