@import "../../scss/main.scss";

.wrapper {
  max-width: 1560px;
  width: 100%;
  margin: 30px auto;
  display: flex;
  gap: 30px;
  justify-content: center;

  @media (max-width: 768px) {
    max-width: 768px;
    width: 100%;
    margin: 20px auto;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    gap: 10px;
  }

  & .blockMenu {
    width: 298px;
    padding: 30px;
    border-radius: 30px;
    background: $white;
    align-self: flex-start;

    @media (max-width: 768px) {
      width: 100%;
      padding: 20px;
      border-radius: 20px;
    }

    & .blockHeaderBtn {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      // margin-bottom: 30px;
      cursor: pointer;

      @media (max-width: 768px) {
        // margin-bottom: 20px;
      }

      & svg {
        color: $primary;
        margin-right: -5px;
      }

      & .imgPreview {
        width: 28px;
        height: 28px;
        border-radius: 100px;
        overflow: hidden;
        object-fit: cover;
      }

      & .backtitle {
        @include text_large_bold;
        color: $primary;
        line-height: 20px;
        font-family: $primary-font;
      }
    }
  }

  & .settingsBlock {
    max-width: 840px;
    width: 100%;
    background: $white;
    padding: 30px;
    border-radius: 30px;

    @media (max-width: 768px) {
      max-width: 768px;
      padding: 20px;
      border-radius: 20px;
    }

    & .title {
      @include desktop_h4;

      @media (max-width: 768px) {
        @include mobile_h4;
      }
    }

    & .contentContainer {
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin-top: 30px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      & .previewBig {
        width: 226px;
        height: 226px;
        border-radius: 1000px;
        object-fit: cover;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        & .penIcon {
          position: absolute;
          right: 0;
          bottom: 0px;
          & svg {
            color: $primary;
          }
        }
      }
      & .inputContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 1;

        & .savebtnWrapper {
          width: 217px;
          display: flex;
          align-self: flex-end;
          margin-top: 10px;

          @media (max-width: 768px) {
            width: 100%;
            margin-top: 0;
          }
        }
      }
    }
  }
}

.inputPhoto {
  display: none;
}
