//fonts
$primary-font: "Gilroy", "sans-serif";
$secondary-font: "Inter", "sans-serif";

//colors
$white: #ffffff;
$black: #000000;
$textblack: #131313;
$textdark: #3a3a3a;
$grayAf: #afafaf;
$grayE4: #e4e4e4;
$grayF8: #f8f8f8;
$gray3A: #3a3a3a;
$gray7C: #7c8691;
$green: #60c055;
$orange: #f05122;
$mint: #79dcd5;
$yellow: #fcd930;
$primary: #8c42e3;
$primaryOpacity: #8c42e326;
$error: #ee3758;
$orangeIcon: #fe7d47;
$orange33: #f0512233;
