@import "../../scss/main.scss";

.contentBox {
  width: 407px;

  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
    & .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 15px;
      background: #fcd93026;
    }

    & h5 {
      @include desktop_h5;
    }
    & .btnContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      & .typeBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        @include desktop_h5;
        background-color: $grayF8;
        border-radius: 10px;
        padding: 11px 10px;
        cursor: pointer;
      }

      & .typeBtnActive {
        background: $yellow;
        color: $white;
      }
    }
  }

  & .sliderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    & .sliderValueWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      margin-bottom: 15px;
      & .inputWrapper {
        width: 110px;
      }
    }

    & span {
      @include desktop_h2;
      line-height: 38px;
    }

    & .sliderOptionsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      & .sliderOption {
        @include text_x_small_regular;
        color: $grayAf;
      }
    }
  }
}
