@import "../../scss/main.scss";

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

p {
  @include desktop_h5;
  flex: 1;

  @media (max-width: 768px) {
    @include mobile_h5;
  }
}

.btnWrapper {
  width: 100px;
}

.editIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  position: absolute;
  right: 16px;
  top: 12px;
  background: $white;

  & .editIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-width: 2px;
    border-style: dashed;
    border-radius: 100px;
    box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
  }
}

.lockedWrapper {
  width: 100px;
  height: 50px;
  border: 2px dashed;
  border-radius: 20px;
  filter: blur(2px);
}

.lockedIconWrapper {
  top: 13px;
  left: 37px;
  position: absolute;
  z-index: 999;
}

.iconWrapper {
  width: 48px;
  height: 48px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & svg {
  }
}

.infoBtn {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  position: absolute;
  left: -5px;
  top: -5px;
  box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  & .infoBlock {
    padding: 10px 15px;
    @include text_small_regular;
    color: $textdark;
    width: 275px;
    position: absolute;
    background: $white;
    z-index: 99999;
    bottom: -50px;
    left: -20px;
    box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  & .infoBlockBottom {
    padding: 10px 15px;
    @include text_small_regular;
    color: $textdark;
    width: 275px;
    position: absolute;
    background: $white;
    z-index: 99999;
    bottom: -90px;
    left: -45px;
    box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
}
