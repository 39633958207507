@import "../../scss/main.scss";

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background: $white;

  @media (max-width: 768px) {
    height: 55px;
  }

  & h1 {
    @include desktop_h4;

    @media (max-width: 768px) {
      @include mobile_h4;
    }
  }
}

.main {
  flex: 1;
  background-image: url("../../assets/images/SignBackground.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
