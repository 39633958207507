@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Black"), local("Gilroy-Black"),
    url("Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Semibold"), local("Gilroy-Semibold"),
    url("Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Extrabold"), local("Gilroy-Extrabold"),
    url("Gilroy-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Heavy"), local("Gilroy-Heavy"),
    url("Gilroy-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Thin"), local("Gilroy-Thin"),
    url("Gilroy-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Regular"), local("Inter-Regular"),
    url("Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Black"), local("Inter-Black"),
    url("Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Semi Bold"), local("Inter-SemiBold"),
    url("Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Bold"), local("Inter-Bold"),
    url("Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Extra Bold"), local("Inter-ExtraBold"),
    url("Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Medium"), local("Inter-Medium"),
    url("Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
