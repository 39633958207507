@import "../../scss/main.scss";

.contentBox {
  border-radius: 30px;
  background: $white;
  border: none;
  outline: none;
  width: 465px;
  padding: 40px 40px 20px;
  position: relative;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-width: 768px;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }

  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    & h4 {
      @include desktop_h4;
      color: $textblack;

      @include mobile_h4;
    }

    & .closeBtn {
      position: absolute;
      right: 25px;
      top: 25px;
      cursor: pointer;
      @media (max-width: 768px) {
        top: 20px;
        right: 20px;
      }
    }
  }

  & .inputsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    & .sendBtnWrapper {
      margin-top: 5px;
    }
  }
}

.imgWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 385px;
  background-color: $white;
  padding: 40px;
  border-radius: 30px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-width: 768px;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }

  & .closeBtn {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
  }

  & h4 {
    @include desktop_h4;
    text-align: center;

    @media (max-width: 768px) {
      @include mobile_h4;
    }
  }

  & p {
    @include text_medium_regular;
    text-align: center;
    margin-bottom: 20px;
    color: $textdark;

    @media (max-width: 768px) {
      @include text_small_regular;
    }
  }
}

.codeInput {
  padding: 13px 20px;
  border-radius: 15px;
  border: 1px solid $grayE4;
  width: 343px;
  @include text_x_large_bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 768px) {
    @include text_large_bold;
    padding: 10px 18px;
  }

  &:focus {
    outline: none;
  }
}

.inputWrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.error {
  @include text_small_bold;
  text-align: center;
  color: $error;

  @media (max-width: 768px) {
    @include text_x_small_bold;
  }
}

.success {
  @include text_small_bold;
  text-align: center;
  color: $green;
}

.supportWrapper {
  align-self: center;
  @include text_small_regular;

  a {
    @include text_small_medium;
    color: $primary;
  }
}
