@import "../../scss/main.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    border-radius: 6px;
    cursor: pointer;
    border: 2px solid $grayE4;
  }

  & .title {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    @include text_medium_regular;
    color: $gray3A;
  }

  & .checkedTitle {
    text-decoration: line-through;
  }

  & .points {
    flex: 0;
    @include text_medium_bold;
    color: $primary;
  }

  & .checkedPoints {
    color: $grayAf;
  }
}
