@import "../../scss/main.scss";

.wrapper {
  background: $white;
  border-radius: 30px;
  padding: 30px;
  width: 407px;

  @media (max-width: 768px) {
    padding: 20px;
    border-radius: 20px;
  }

  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    & .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #60c05533;
      width: 48px;
      height: 48px;
      border-radius: 15px;

      & svg {
        color: $green;
      }
    }

    & h5 {
      @include desktop_h5;
    }
  }

  & .description {
    margin-top: 10px;
    @include text_small_regular;

    @media (max-width: 768px) {
      margin-bottom: 15px;
    }

    & span {
      @include text_small_bold;
    }
  }

  & .refferalBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 13px 20px;
    border: 1px solid $grayE4;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 20px;
    position: relative;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }

    & .link {
      flex: 1;
      @include text_small_regular;
    }

    & svg {
      color: $grayAf;
    }
  }

  & .rewardWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 12px;
    background: $grayF8;
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      margin-top: 0px;
      width: 100%;
    }

    & .reward {
      @include desktop_h3;
    }

    & .subtitle {
      @include text_x_small_regular;
      color: $grayAf;
    }
  }
}

.descriptionWrapper {
  margin-bottom: 10px;
}

.descriptionBigWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  margin-top: 15px;
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 10px;
  }
}

.successCopied {
  position: absolute;
  @include text_small_regular;
  color: $textdark;
  top: -40px;
  right: 0px;
  z-index: 999;
  background: $white;
  border: 1px solid $grayE4;
  border-radius: 10px;
  padding: 4px 20px;
}
