.wrapper {
  max-width: 1280px;
  margin: 0 auto;

  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
  }
}
