@import "../../scss/main";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: 16px 0px;
  border-top: 1px solid $grayE4;
  border-bottom: 1px solid transparent;
  transition: all 1s ease;

  & p {
    @include desktop_h5;

    @media (max-width: 768px) {
      @include mobile_h5;
    }
  }
}

.wrapper:last-child {
  border-bottom: 1px solid $grayE4;
}

.wrapperActive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: 16px 0px;
  border-top: 1px solid $grayE4;
  border-bottom: 1px solid $grayE4;
}

.answer {
  @include text_medium_regular;
  padding: 15px 0;
  // transition: max-height 1s ease;
}

.answerContainer {
  // max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s linear;
}

.answerContainerActive {
  // max-height: initial;
}
