@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  transition: all linear 0.2s;
  border-radius: 0px;

  @media (max-width: 768px) {
    align-items: flex-start;
  }

  &:hover {
    background: rgba(250, 250, 250, 1);
    cursor: pointer;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $grayE4;
  }

  & .img {
    min-width: 70px;
    max-width: 70px;
    height: 60px;
    border-radius: 10px;
    margin-left: 16px;
    object-fit: cover;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  & .infoWrapper {
    display: flex;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;
    }
  }

  & .title {
    @include desktop_h5;
    margin-left: 10px;
    flex: 1;

    @media (max-width: 768px) {
      @include mobile_h5;
      margin-left: 0;
    }
  }

  & .ingredientTitle {
    display: flex;
    width: 124px;
    align-items: center;
    & span {
      margin-left: 7px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      font-family: $secondary-font;
    }
  }

  // & .cookTime {
  //   width: 100px;
  //   display: flex;
  //   align-items: center;
  //   margin-left: 25px;

  //   & span {
  //     margin-left: 7px;
  //     font-weight: 400;
  //     font-size: 14px;
  //     line-height: 24px;
  //     font-family: $secondary-font;
  //   }
  // }

  & .tagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 269px;
    margin-left: 25px;

    @media (max-width: 768px) {
      margin-left: 0;
      gap: 3px;
      width: 100%;
      margin-top: 10px;
    }

    & .tag {
      padding: 3px 10px;
      background: rgba(140, 66, 227, 0.15);
      border-radius: 8px;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: $primary;
    }
  }

  & .menuBtn {
    cursor: pointer;
  }
}
.menuWrapper {
  position: absolute;
  right: -20px;
  top: 60px;
  width: 125px;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  background: #fff;
  z-index: 99;
  padding: 10px;

  & .copyIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .menuButton {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 6px 8px;
    border-radius: 10px;
    transition: all linear 0.2s;
    cursor: pointer;

    & .deleteLoader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: #f8f8f8;
    }

    & span {
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
      color: #000;
    }
  }
}
