@import "../../scss/main.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.barItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100px;

  & .value {
    @include desktop_h4;
    color: $green;
  }

  & .negativeValue {
    color: $error;
  }

  & .fillBlock {
    display: block;
    background: #fcd930;
    width: 50px;
    height: 171px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    overflow: hidden;
    & .differentBlock {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
    }
  }

  & .label {
    @include text_small_bold;
    color: "#000";
    text-align: center;
  }
}
