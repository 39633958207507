@import "../../scss/main.scss";

.wrapper {
  width: 368px;
  border-radius: 15px;
  padding: 27px 30px;
  background: $white;
  position: absolute;
  right: -20px;
  top: 56px;
  box-shadow: 0px 11px 50px rgba(0, 0, 0, 0.07);
  cursor: default;

  @media (max-width: 768px) {
    width: 100%;
    top: 50px;
    left: 0;
    box-shadow: none;
    border-radius: 0;
  }

  & .header {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    & .dayBtn {
      width: 144px;
      padding: 9px 30px;
      @include text_small_bold;
      border-radius: 16px;
      text-align: center;
      color: #000;
    }

    & .activeDayBtn {
      width: 144px;
      text-align: center;
      padding: 9px 30px;
      @include text_small_bold;
      border-radius: 16px;
      background: $primaryOpacity;
      color: $primary;
    }
  }

  & .contentContainer {
    margin-bottom: 20px;

    & .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
    }

    & .scrollList {
      max-height: 400px;
      overflow-y: auto;
      padding-right: 10px;

      & .list {
        & .title {
          @include text_medium_bold;
          text-transform: uppercase;
          color: #000;
          margin-top: 20px;
        }
        & .ingredients {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 0;
          &:not(:last-child) {
            border-bottom: 1px solid #e4e4e4;
          }
          & .title1 {
            @include text_small_regular;
            color: #3a3a3a;
            flex: 1;
          }

          & .amountWrapper {
            display: flex;
            align-items: center;
            column-gap: 4px;

            & span {
              @include text_small_bold;
              color: #000;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
