@import "../../scss/main.scss";

.wrapper {
  width: 516px;
  height: 340px;
  position: relative;
  border-radius: 25px;
  overflow: hidden;

  @media (max-width: 1440px) {
    max-width: 516px;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 240px;
    border-radius: 15px;
    max-width: initial;
  }

  & .preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 516px;
    height: 340px;
    border-radius: 25px;
    overflow: hidden;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 100%;
      height: 240px;
      border-radius: 15px;
    }
  }
}

.blockWrapper {
  display: flex;
  flex-direction: row;
  margin-left: 26px;
  cursor: pointer;
  box-sizing: border-box;

  @media (max-width: 768px) {
    margin-left: 0px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }

  & .blockContent {
    margin-left: 40px;
    margin-bottom: 20px;
    width: 100%;

    @media (max-width: 768px) {
      margin-left: 24px;
      margin-bottom: 10px;
      flex: 1;
    }
    & .subtitle {
      @include text_small_regular;
      color: $grayAf;
      text-align: center;
      margin-top: 20px;

      @media (max-width: 768px) {
        @include text_x_small_regular;
        margin-top: 10px;
      }
    }
  }
}
.blur {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
  border-radius: 25px;
}

.progressLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .firstLine {
    width: 4px;
    height: 50%;

    @media (max-width: 768px) {
      width: 2px;
    }
  }
  & .secondLine {
    width: 4px;
    height: 50%;
    @media (max-width: 768px) {
      width: 2px;
    }
  }

  & .iconContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 100px;

    @media (max-width: 768px) {
      width: 38px;
      height: 38px;
    }
    & .iconInnerContainer {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;

      @media (max-width: 768px) {
        width: 28px;
        height: 28px;
      }

      & svg {
        color: $white;
      }
    }
  }
}

.contentWrapperFinished {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 30px 30px 30px;
  z-index: 99;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 17.71%,
    rgba(0, 0, 0, 0) 30.73%,
    rgba(0, 0, 0, 0.7) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 25px;

  @media (max-width: 768px) {
    border-radius: 15px;
    padding: 20px;
  }
  & .header {
    width: 84px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    @media (max-width: 768px) {
      justify-content: end;
    }
  }

  & .descriptionWrapper {
    & .calendarWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;

      & svg {
        color: $white;
      }

      & p {
        @include text_small_regular;
        color: $white;

        @media (max-width: 768px) {
          @include text_x_small_regular;
        }
      }
    }

    & h4 {
      @include desktop_h4;
      color: $white;
      margin-top: 10px;
      margin-bottom: 15px;

      @media (max-width: 768px) {
        @include mobile_h4;
      }
    }
  }
}

.contentWrapperInProgress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 30px 30px 30px;
  z-index: 99;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 17.71%,
    rgba(0, 0, 0, 0) 30.73%,
    rgba(0, 0, 0, 0.7) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 25px;
  @media (max-width: 768px) {
    padding: 20px;
    border-radius: 15px;
  }
  & .header {
    width: 100%;
    display: flex;
    height: 14px;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
    @media (max-width: 768px) {
      height: 12px;
      justify-content: end;
    }
    & .progressWrapper {
      width: 100%;
    }

    & p {
      @include text_small_bold;
      line-height: 14px;
      color: $white;
      margin-left: 15px;
    }
  }

  & .descriptionWrapper {
    & .timerWrapper {
      background: #f05122;
      padding: 8px 20px;
      border-radius: 20px;

      @media (max-width: 768px) {
        border-radius: 10px;
        padding: 4px 20px;
      }

      & .wrapperItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
        & .divider {
          background: $white;
          width: 1px;
          height: 30px;

          @media (max-width: 768px) {
            height: 20px;
          }
        }

        & .blockItem {
          display: flex;
          flex-direction: column;
          align-items: center;

          & h4 {
            @include desktop_h5;
            margin: 0;

            @media (max-width: 768px) {
              @include mobile_h5;
              font-size: 14px;
              line-height: 18px;
            }
          }

          & p {
            @include text_x_small_regular;
            font-size: 10px;
            line-height: 12px;
            margin: 0;
            padding: 0;
            color: $white;

            @media (max-width: 768px) {
              font-size: 8px;
              line-height: 8px;
            }
          }
        }
      }
    }

    & .calendarWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;

      & svg {
        color: $white;
      }

      & p {
        @include text_small_regular;
        color: $white;

        @media (max-width: 768px) {
          @include text_x_small_regular;
        }
      }
    }

    & h4 {
      @include desktop_h4;
      color: $white;
      margin-top: 10px;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        @include mobile_h4;
      }
    }
  }
}

.contentWrapperBlocked {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 30px 30px 30px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 17.71%,
    rgba(0, 0, 0, 0) 30.73%,
    rgba(0, 0, 0, 0.7) 100%
  );

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 25px;
  @media (max-width: 768px) {
    padding: 20px;
    border-radius: 15px;
  }
  & .blockIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    & svg {
      color: $white;
    }
  }

  & .header {
    width: 84px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    @media (max-width: 768px) {
      justify-content: end;
    }
  }

  & .descriptionWrapper {
    & .calendarWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;

      & svg {
        color: $white;
      }

      & p {
        @include text_small_regular;
        color: $white;

        @media (max-width: 768px) {
          @include text_x_small_regular;
        }
      }
    }

    & h4 {
      @include desktop_h4;
      color: $white;
      margin-top: 10px;
      margin-bottom: 15px;

      @media (max-width: 768px) {
        @include mobile_h4;
        margin-bottom: 0px;
      }
    }
  }
}
