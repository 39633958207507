@import "../../scss/main";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 407px;
  padding: 30px;
  border-radius: 30px;
  background: $white;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    gap: 15px;
  }

  & h4 {
    @include desktop_h4;
    text-align: center;
    @media (max-width: 768px) {
      @include mobile_h4;
    }
  }

  & h3 {
    @include desktop_h3;
    color: $primary;
    text-align: center;
    @media (max-width: 768px) {
      @include mobile_h3;
    }
  }
}
