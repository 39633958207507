@import "../../scss/main";

.container {
  background: $white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.02);
  width: 100%;
  position: sticky;
  z-index: 99999;
  height: 70px;

  @media (max-width: 768px) {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  & .progressWrapperMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    // height: 100%;
    width: 100%;

    & .progressLineContainer {
      height: 13px;
      width: 100%;
    }
  }

  &Active {
    z-index: 999999;
    // pointer-events: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 11px 50px;

  @media (max-width: 768px) {
    padding: 11px 16px;
  }

  & .progressWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: 768px) {
      width: 100%;
    }

    & .progressLineContainer {
      width: 100%;
      // height: 12px;

      // @media (max-width: 768px) {
      //   height: 8px;
      // }
    }

    & span {
      @include text_small_bold;
      line-height: 14px;
      margin-left: 14px;

      @media (max-width: 768px) {
        @include text_x_small_bold;
        line-height: 10px;
      }
    }
  }

  & .logo {
    @include desktop_h4;
    cursor: pointer;

    @media (max-width: 768px) {
      @include mobile_h4;
    }
  }

  & .logoWrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;

    @media (max-width: 400px) {
      column-gap: 5px;
    }
  }

  .navigationMenu {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .infoBtn {
    color: $orange;
    // min-width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 9px;
    border: 2px solid $orange;
    background-color: $orange33;
    border-radius: 15px;

    width: 44px;
    height: 44px;

    @media (max-width: 768px) {
      border: 3px solid $grayF8;
      // padding: 6px;
      border-radius: 10px;

      width: 38px;
      height: 38px;
    }

    @media (max-width: 400px) {
      min-width: 38px;
    }

    &Active {
      cursor: pointer;
    }
  }

  & .mobileHeaderDivider {
    width: 1px;
    height: 20px;
    background: $grayE4;
    margin: 0 10px;
    @media (max-width: 400px) {
      margin: 0 6px;
    }
  }

  //meal btn
  & .mealPlanBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 20px;
    border-radius: 15px;
    // margin-left: 15px;
    position: relative;
    margin-right: 10px;
    background: rgba(96, 192, 85, 0.2);
    cursor: pointer;

    @media (max-width: 1024px) {
      margin-left: 0px;
      & span {
        display: none;
      }
    }
    @media (max-width: 768px) {
      border: 3px solid $grayF8;
      padding: 6px;
      border-radius: 10px;
      margin-left: 10px;
      margin-right: 0;
      position: static;
    }
    @media (max-width: 400px) {
      margin-left: 6px;
      padding: 5px;
    }

    & svg {
      color: $green;
    }

    & span {
      margin-left: 10px;
      @include text_small_bold;
      color: $green;
    }
  }

  & .mealPlanBtnDisable {
    cursor: default;
    position: relative;
  }

  //shoping btn
  & .shopingListbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    border: 4px solid $grayF8;
    border-radius: 15px;
    position: relative;
    margin-right: 30px;
    cursor: pointer;

    @media (max-width: 1024px) {
      margin-right: 15px;
      & span {
        display: none;
      }
    }
    @media (max-width: 768px) {
      border: 3px solid $grayF8;
      padding: 6px;
      border-radius: 10px;
      margin-left: 10px;
      margin-right: 0;
      position: static;
    }
    @media (max-width: 400px) {
      margin-left: 6px;
      padding: 5px;
    }

    & svg {
      color: $grayAf;
    }

    & > span {
      margin-left: 10px;
      @include text_small_bold;
    }
  }

  & .shopingListbtnActive {
    border: 4px solid $primaryOpacity;
    color: $primary;
    @media (max-width: 768px) {
      border: 3px solid $primaryOpacity;
    }
    & svg {
      color: $primary;
    }
  }

  & .shopingListbtnDisable {
    cursor: default;
    position: relative;
  }

  //profile btn
  .myProfileBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    border: 4px solid $grayF8;
    border-radius: 15px;
    margin-left: 15px;
    position: relative;
    cursor: pointer;

    @media (max-width: 1024px) {
      margin-left: 10px;
    }
    @media (max-width: 768px) {
      border: 3px solid $grayF8;
      padding: 6px;
      border-radius: 10px;
      margin-left: 10px;
      position: static;
    }
    @media (max-width: 400px) {
      margin-left: 6px;
      padding: 5px;
    }

    & .profileMenuWrapper {
      width: 245px;
      border-radius: 15px;
      padding: 27px 30px;
      background: $white;
      position: absolute;
      right: -20px;
      top: 56px;
      box-shadow: 0px 11px 50px rgba(0, 0, 0, 0.07);
      cursor: pointer;

      @media (max-width: 768px) {
        width: 100%;
        top: 50px;
        left: 0;
        box-shadow: none;
        border-radius: 0;
      }

      & .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        padding-bottom: 20px;
        border-bottom: 1px solid $grayE4;
        & .avatar {
          min-width: 44px;
          max-width: 44px;
          height: 44px;
          border-radius: 100px;
          overflow: hidden;
          object-fit: cover;
        }

        & .description {
          & .title {
            @include desktop_h5;
            color: $textblack;
          }
          & .viewProfileBtn {
            @include text_medium_bold;
            color: $primary;
            cursor: pointer;
          }
        }
      }
      & .btnWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;

        @media (max-width: 768px) {
          align-items: flex-start;
        }

        & .navBtn {
          @include desktop_h5;
          color: $textblack;
          cursor: pointer;
        }
      }
    }

    & svg {
      color: $grayAf;
    }

    & span {
      margin-left: 10px;
      @include text_small_bold;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  & .myProfileBtnActive {
    border: 4px solid $primaryOpacity;
    color: $primary;
    @media (max-width: 768px) {
      border: 3px solid $primaryOpacity;
    }
    & svg {
      color: $primary;
    }
  }

  & .myProfileBtnDisable {
    cursor: default;
    position: relative;
  }

  //notifications btn
  .notificationBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 4px solid $grayF8;
    border-radius: 15px;
    position: relative;
    cursor: pointer;

    @media (max-width: 768px) {
      position: static;
      border: 3px solid $grayF8;
      padding: 6px;
      border-radius: 10px;
    }
    @media (max-width: 400px) {
      padding: 5px;
    }

    & .notificationIndicatorWrapper {
      width: 16px;
      height: 16px;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      position: absolute;
      top: -6px;
      right: -6px;
      z-index: 99;

      @media (max-width: 768px) {
        right: 60px;
        top: 4px;
      }

      & .notificationIndicator {
        width: 6px;
        height: 6px;
        border-radius: 100px;
        background: $error;
      }
    }

    & .notificationMenu {
      width: 368px;
      border-radius: 15px;
      padding: 27px 30px;
      background: $white;
      position: absolute;
      right: -20px;
      top: 56px;
      box-shadow: 0px 11px 50px rgba(0, 0, 0, 0.07);
      cursor: pointer;

      @media (max-width: 768px) {
        width: 100%;
        top: 50px;
        left: 0;
        box-shadow: none;
        border-radius: 0;
      }

      & .notificationList {
        max-height: 220px;
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }

      & .emptyTitle {
        @include text_small_regular;
        color: $gray3A;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 1px solid $grayE4;
      }
      & .footer {
        margin-top: 15px;
        & .title {
          @include text_small_bold;
          color: $textblack;
        }
        & .subtitle {
          @include text_small_regular;
          color: $textdark;
        }
      }
    }

    & svg {
      color: $grayAf;
    }
  }

  & .notificationBtnActive {
    border: 4px solid $primaryOpacity;
    color: $primary;

    @media (max-width: 768px) {
      border: 3px solid $primaryOpacity;
    }
    & svg {
      color: $primary;
    }
  }

  & .notificationBtnDisable {
    cursor: default;
    position: relative;
  }
}

.tooltip {
  right: 35%;
  top: 50px;

  &::after {
    right: 20px;
    rotate: 90deg;
    top: -16px;
    transform: translateX(50%);
  }

  &Profile {
  }
  &Notif {
    right: -10px;

    &::after {
      left: 89%;
    }
  }
  &Shoping {
  }
  &Meal {
    top: 58px;
  }

  @media (max-width: 1024px) {
    &Shoping {
      right: 0;
    }
    &Meal {
      right: 4px;
    }
  }
  @media (max-width: 768px) {
    right: -8px;
    top: 43px;
    &Meal,
    &Shoping,
    &Profile {
      right: -8px;
      top: 43px;
    }
    &Notif {
      right: -13px;
      top: 43px;
    }
  }
  @media (max-width: 425px) {
    &Profile {
    }

    &Notif {
      right: -56px;
      &::after {
        left: 75%;
      }
    }

    &Shoping {
      right: -115px;
      &::after {
        left: 57%;
      }
    }

    &Meal {
      right: -163px;
      &::after {
        left: 42%;
      }
    }
  }
}
