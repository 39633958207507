@import "../../scss/main";

.wrapper {
  width: 100%;
  height: 66px;
  background: linear-gradient(45deg, #ff844d 0%, #f6310e 100%);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.02);
  padding: 0 50px;
  position: fixed;
  z-index: 99999999;
}

.contentContainer {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1280px;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: center;
  }
  & .title {
    @include text_medium_regular;
    color: $white;

    @media (max-width: 768px) {
      display: none;
    }

    & span {
      @include text_medium_bold;
    }
  }
}
