@import "../../scss/main.scss";

$mobileAdapriveWidth: 950px;

.fasting {
  width: 30px;
  height: 30px;
}

.wrapperLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  @media (max-width: $mobileAdapriveWidth) {
    height: 70vh;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1760px;
  column-gap: 30px;
  margin: 30px auto 20px;

  @media (max-width: 1860px) {
    margin: 30px 50px 20px;
    column-gap: 20px;
  }

  @media (max-width: $mobileAdapriveWidth) {
    flex-direction: column;
    max-width: calc(100vw - 32px);
    margin: 20px 16px 0;
  }

  & .daysList {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & .boostcampContent {
    max-width: 946px;
    width: 100%;
    // margin-left: 30px;
    // margin-right: 30px;

    @media (max-width: $mobileAdapriveWidth) {
      max-width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }

    & .boostcampHeader {
      background: $white;
      border-radius: 30px;
      padding: 30px;

      @media (max-width: $mobileAdapriveWidth) {
        padding: 20px;
        border-radius: 20px;
      }

      & .timerWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 30px;
        @media (max-width: $mobileAdapriveWidth) {
          // width: 80vw;
          gap: 4px;
          margin-bottom: 20px;
        }
      }
      & .timerTitle {
        @include desktop_h5;
        text-align: center;
        margin: 20px 0px;
        @media (max-width: $mobileAdapriveWidth) {
          @include mobile_h5;
          margin-top: 10px 0px;
        }
      }
      & .dayTitle {
        @include desktop_h2;
        margin-bottom: 15px;

        @media (max-width: $mobileAdapriveWidth) {
          @include mobile_h2;
        }
      }

      & .daySubtitle {
        @include text_large_regular;
        color: $gray3A;
        margin-bottom: 30px;

        @media (max-width: $mobileAdapriveWidth) {
          @include text_medium_regular;
          margin-bottom: 15px;
        }
      }

      & .dayPreview {
        width: 100%;
        height: 390px;
        border-radius: 30px;
        overflow: hidden;
        object-fit: cover;

        @media (max-width: $mobileAdapriveWidth) {
          height: 227px;
          border-radius: 20px;
        }
      }
    }

    & .dayOverviewWrapper {
      position: relative;
      background: $white;
      border-radius: 30px;
      padding: 30px;
      margin-top: 20px;

      @media (max-width: $mobileAdapriveWidth) {
        padding: 20px;
        border-radius: 20px;
      }

      & .dayOverview {
        @include text_medium_regular;
        color: $gray3A;
        margin-bottom: 30px;
      }

      & .completeDayBtns {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
      }

      & .completeDayBtnWrapper {
        position: relative;
        margin: 0 auto;
        width: 347px;
      }
    }
  }

  & .tasksList {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: $mobileAdapriveWidth) {
      margin-bottom: -122px;
    }

    & .tasksListContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 30px;
      padding: 30px;
      background-color: #f9f2d0;
    }

    & .tasksListTitle {
      @include desktop_h3;

      @media (max-width: $mobileAdapriveWidth) {
        @include mobile_h3;
        margin-bottom: 15px;
      }
    }

    & .trackersBlurWrapper {
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px);
    }

    & .openDayBtn {
      background: $white;
      border: 4px solid $primary;
      border-radius: 30px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 407px;
      cursor: pointer;

      & .dayImg {
        width: 61px;
        height: 54px;
        border-radius: 15px;
        overflow: hidden;
        object-fit: cover;
      }

      & .dayTitle {
        margin-left: 20px;
        @include desktop_h5;
        width: 143px;
        margin-right: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      & .changeDayBtn {
        @include text_small_bold;
        color: $primary;
        margin-right: 10px;
        width: 81px;
        text-align: end;
      }
    }

    & .divider {
      height: 1px;
      background: $grayE4;
      margin-top: 25px;
      margin-bottom: 25px;
      @media (max-width: $mobileAdapriveWidth) {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }

  .checklistContainer {
    position: relative;

    &Active {
      z-index: 999991;

      & > div:first-of-type {
        pointer-events: none;
      }
    }
    &Tooltip {
      left: 0;
      top: 50%;
      transform: translate(-97%, -50%);

      &::after {
        right: -8px;
        rotate: 180deg;
      }

      &First {
        &::after {
          top: 24px;
        }

        @media (max-width: $mobileAdapriveWidth) {
          left: 50%;
          top: 50px;
          bottom: initial;
          transform: translate(-50%, -100%);
          &::after {
            left: initial;
            right: 50%;
            rotate: 270deg;
            top: initial;
            bottom: -17px;
            transform: translateX(50%);
          }
        }
      }

      &Second {
        top: initial;
        bottom: 20px;
        transform: translate(-97%, 0);

        &::after {
          bottom: 45px;
        }

        @media (max-width: $mobileAdapriveWidth) {
          left: 50%;
          top: initial;
          bottom: 0;
          transform: translate(-50%, 97%);
          &::after {
            left: initial;
            right: 50%;
            rotate: 90deg;
            top: -16px;
            transform: translateX(50%);
          }
        }
      }
    }
  }

  .dailyRemindersContainer {
    position: relative;

    &Active {
      z-index: 999991;

      & > div:first-of-type {
        pointer-events: none;
      }
    }
    &Tooltip {
      left: 0;
      top: 50%;
      transform: translate(-97%, -50%);

      &::after {
        right: -8px;
        rotate: 180deg;
        top: 50%;
        transform: translateY(50%);
      }

      @media (max-width: $mobileAdapriveWidth) {
        left: 50%;
        top: 0;
        bottom: initial;
        transform: translate(-50%, -97%);
        &::after {
          left: initial;
          right: 50%;
          rotate: 270deg;
          top: initial;
          bottom: -17px;
          transform: translateX(50%);
        }
      }
    }
  }

  .btnMeal {
    position: relative;

    &Active {
      z-index: 999991;
      & > button {
        pointer-events: none;
      }
    }
    &Tooltip {
      left: 0;
      top: 40%;
      transform: translate(-97%, -50%);

      &Mob {
      }

      &::after {
        right: -8px;
        rotate: 180deg;
        top: 50%;
        transform: translateY(50%);
      }

      @media (max-width: $mobileAdapriveWidth) {
        left: 50%;
        top: 0;
        bottom: initial;
        transform: translate(-50%, -97%);
        &::after {
          left: initial;
          right: 50%;
          rotate: 270deg;
          top: initial;
          bottom: -17px;
          transform: translateX(50%);
        }
      }
    }
  }

  .btnVideo {
    position: relative;

    &Active {
      z-index: 999991;
      & > button {
        pointer-events: none;
      }
    }
    &Tooltip {
      left: 0;
      top: 50%;
      transform: translate(-97%, -50%);
      &::after {
        right: -8px;
        rotate: 180deg;
        top: 50%;
        transform: translateY(50%);
      }

      @media (max-width: $mobileAdapriveWidth) {
        left: 50%;
        top: 0;
        bottom: initial;
        transform: translate(-50%, -97%);
        &::after {
          left: initial;
          right: 50%;
          rotate: 270deg;
          top: initial;
          bottom: -17px;
          transform: translateX(50%);
        }
      }
    }
  }
}

.daysListItem {
  position: relative;

  &Active {
    z-index: 999991;

    & > div:first-of-type {
      pointer-events: none;
    }
  }

  &Tooltip {
    right: 0;
    top: 50%;
    transform: translate(97%, -50%);

    &::after {
      left: -8px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 1440px) {
      right: initial;
      left: 0;
      transform: translate(-97%, -50%);

      &::after {
        left: initial;
        right: -8px;
        rotate: 180deg;
        top: 50%;
        transform: translateY(50%);
      }
    }
    @media (max-width: $mobileAdapriveWidth) {
      left: 50%;
      top: initial;
      bottom: 0;
      transform: translate(-50%, 97%);
      &::after {
        left: initial;
        right: 50%;
        rotate: 90deg;
        top: -16px;
        transform: translateX(50%);
      }
    }
  }
}

.daysMenu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 999999;
  margin-top: 70px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;

  &Overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999991;
    background-color: rgba(19, 19, 19, 0.6);
  }

  & .menuWrapper {
    flex: 1;
  }

  & .menuModal {
    background: $white;

    padding: 30px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px;

    max-width: 470px;

    &Tooltip {
      overflow: initial;
    }

    & .modalHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 30px;

      & .crossBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      & p {
        margin-top: 3.5px;
        margin-left: 10px;
      }
    }
  }
}

.stickyMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 56px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 40px;
  margin-right: auto;
  margin-left: 16px;
  z-index: 99999;
}

.blurWrapper {
  filter: blur(6px);
  user-select: none;
}

.blockIcon {
  position: absolute;
  // top: 50%;
  top: 200px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
  color: $primary;
}
