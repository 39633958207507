@import "../../scss/main.scss";

.wrapper {
  background: $white;
  border-radius: 30px;
  padding: 30px;
  width: 407px;

  @media (max-width: 1440px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }

  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
    & .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f0512233;
      width: 48px;
      height: 48px;
      border-radius: 15px;

      & svg {
        color: $orange;
      }
    }

    & h5 {
      @include desktop_h5;
    }
  }

  & .description {
    @include text_small_regular;

    & span {
      @include text_small_bold;
    }
  }

  & .progressWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    margin-top: 20px;
    position: relative;
    & .points {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include text_small_bold;
    }

    & .mysteryBoxWrapper {
      position: absolute;
      right: 30%;
      transform: translateX(+50%);

      & .mysteryBoxContainer {
        // -webkit-box-shadow: 0px 0px 20px 5px rgba(240, 81, 34, 0.8);
        // -moz-box-shadow: 0px 0px 20px 5px rgba(240, 81, 34, 0.8);
        // box-shadow: 0px 0px 20px 5px rgba(240, 81, 34, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 5px solid $white;
        box-sizing: content-box;
        border-radius: 100px;
        background: $grayE4;

        cursor: pointer;
      }

      & .mysteryBoxContainerAvailable {
        background: rgba(240, 81, 34, 0.5);
        border: 5px solid rgba(240, 81, 34, 0.05);

        -webkit-box-shadow: 0px 0px 20px 3px rgba(240, 81, 34, 0.8);
        -moz-box-shadow: 0px 0px 20px 3px rgba(240, 81, 34, 0.8);
        box-shadow: 0px 0px 20px 3px rgba(240, 81, 34, 0.8);
      }

      & .mysteryBoxPopUp {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 50px;
        align-self: center;
        & .mysteryBoxPopUpContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 199px;
          height: 139px;
          background: $white;
          padding: 16px;
          box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
          border-radius: 15px;

          @media (max-width: 768px) {
            padding: 10px;
          }

          & .title {
            @include desktop_h5;
            margin-top: 10px;

            @media (max-width: 768px) {
              @include mobile_h5;
            }
          }
          & .subtitle {
            @include text_small_regular;
            margin-top: 5px;

            text-align: center;

            @media (max-width: 768px) {
              @include text_x_small_regular;
            }
            & span {
              @include text_small_bold;

              @media (max-width: 768px) {
                @include text_x_small_bold;
              }
            }
          }
        }

        & .mysteryBoxPopUpContainerAvailable {
          width: 325px;
          height: 207px;

          @media (max-width: 1440px) {
            width: 270px;
          }

          @media (max-width: 768px) {
            width: 210px;
            height: 190px;
          }
        }
      }
    }
  }
}
