@import "../../scss/main.scss";

.contentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 541px;

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    width: 100vw;
    position: relative;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  & .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .description {
    @include text_small_regular;
    text-align: center;
    margin-top: 15px;

    @media (max-width: 768px) {
      margin-top: 10px;
    }

    & span {
      @include text_small_bold;
      color: $primary;
    }
  }

  & .points {
    @include desktop_h2;
    color: $primary;
    text-align: center;
    margin-top: 30px;

    @media (max-width: 768px) {
      @include mobile_h2;
      margin-top: 20px;
    }
  }

  & .completeTitle {
    margin-top: 35px;
    @include desktop_h3;
    text-align: center;

    @media (max-width: 768px) {
      @include mobile_h3;
      margin-top: 20px;
    }
  }

  & .progressWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 29px;

    & p {
      @include text_medium_bold;
    }

    & .progressLineContainer {
      display: flex;
      align-items: center;
      width: 100%;
      margin-right: 15px;
      height: 20px;
    }
  }
  & .buttonWrapper {
    width: 217px;
    margin-top: 35px;

    @media (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      margin-top: auto;
      position: absolute;
      bottom: 30px;
    }
  }
}
