@import "../../scss/main.scss";

.wrapper {
  background: $white;
  position: relative;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
}

p {
  @include desktop_h5;
  flex: 1;

  @media (max-width: 768px) {
    @include mobile_h5;
  }
}

.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  & .typeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    @include desktop_h5;
    background-color: $grayF8;
    border-radius: 10px;
    padding: 11px 10px;
    cursor: pointer;
  }

  & .typeBtnActive {
    background: #8c42e3;
    color: $white;
  }
}

.contentBox {
  width: 407px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    width: 100%;
  }

  & .sliderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;

    & .sliderValueWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      & .inputWrapper {
        width: 110px;
      }
      & .btnContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        & .typeBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          @include mobile_h5;
          background-color: $grayF8;
          border-radius: 10px;
          padding: 11px 10px;
          cursor: pointer;
        }

        & .typeBtnActive {
          background: $green;
          color: $white;
        }
      }
    }

    & h2 {
      @include mobile_h2;
    }

    & span {
      @include mobile_h2;
      margin-bottom: 10px;
    }

    & .sliderOptionsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 14px;
      & .sliderOption {
        @include text_x_small_regular;
        color: $grayAf;
      }
    }
  }
}

.recommended {
  @include text_x_small_regular;
  color: $textdark;
  margin-bottom: 10px;
}
