@import "../../scss/main.scss";

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fastingImg {
  width: 32px;
  height: 32px;
}

.wrapper {
  max-width: 1560px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  gap: 30px;

  @media (max-width: 768px) {
    max-width: 768px;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
  }

  & .profileBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    padding: 30px 65px;
    background: $white;
    height: max-content;

    @media (max-width: 768px) {
      padding: 20px;
      border-radius: 20px;
    }

    & svg {
      color: $primary;
    }

    & .avatar {
      width: 160px;
      height: 160px;
      border-radius: 100px;
      overflow: hidden;
      object-fit: cover;

      @media (max-width: 768px) {
        width: 140px;
        height: 140px;
      }
    }

    & .name {
      margin-top: 25px;
      @include desktop_h4;

      @media (max-width: 768px) {
        @include mobile_h4;
        margin-top: 15px;
      }
    }

    & .locationContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      margin-top: 5px;
      & svg {
        color: $grayAf;
      }

      & p {
        @include text_small_regular;
        color: $gray3A;
      }
    }

    & .mailContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      margin-top: 5px;
      margin-bottom: 30px;
      & p {
        @include text_small_regular;
        color: $gray3A;
      }
      & svg {
        color: $grayAf;
      }
    }
  }

  & .statisticBlock {
    width: 100%;
    max-width: 900px;
    border-radius: 30px;
    padding: 30px;
    background-color: $white;

    @media (max-width: 768px) {
      border-radius: 20px;
      padding: 20px;
    }

    & .blockTitle {
      @include desktop_h4;
    }

    & .flexRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 120px;
      margin-top: 20px;
      padding-bottom: 30px;
      border-bottom: 1px solid $grayE4;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 16px;
      }
    }

    & .statisticItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      @media (max-width: 768px) {
        flex-direction: row;
      }

      & .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        background: rgba(238, 55, 88, 0.2);
        width: 48px;
        height: 48px;

        & svg {
          color: $error;
        }
      }

      & .descriptionWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
        }
      }

      & .counter {
        @include desktop_h4;

        @media (max-width: 768px) {
          @include mobile_h4;
        }
      }

      & .subtitle {
        @include text_small_regular;
        color: $gray3A;
        margin-top: -10px;
      }
    }

    & .trackingTimeSection {
      margin-top: 30px;

      & .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;

        & .trackingTitleWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 50px;

          & .trackingTitle {
            @include desktop_h4;
          }
        }

        & .optionsBtnContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }
      & .trackingWidgetsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 25px;

        & .trackingWidgetWrapper {
          width: 168px;
          padding-top: 20px;
          padding-bottom: 20px;
          border-radius: 15px;
          border: 3px solid transparent;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          transition: all 0.2s linear;

          & .iconWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            width: 48px;
            height: 48px;
          }

          & .title {
            @include desktop_h4;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
          }

          & .subtitle {
            @include text_small_regular;
          }

          & .shareWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 7px;

            & svg {
              color: $grayAf;
            }
            & .sharetitle {
              @include text_small_bold;
              color: $grayAf;
              margin-left: 6px;
            }
          }
        }

        & .activeTrackingWidgetWrapper {
          border-width: 3px;
          border-style: solid;
          text-align: center;
        }
      }

      & .trackingGraphContainer {
        width: 100%;
        // overflow-x: hidden;
        // overflow-y: scroll;
      }
    }
  }

  & .tutorialBtns {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
