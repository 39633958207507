@import "../../scss/main.scss";

.timerBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 150px;

  & .timerTitle {
    @include mobile_h5;
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}
