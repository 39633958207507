@import "../../scss/main";

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.main {
  flex: 1;
  background: $grayF8;
  @media (max-width: 768px) {
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999991;
  background-color: rgba(19, 19, 19, 0.6);
}
