@import "../../scss/main.scss";

.wrapper {
  background: $white;
  border-radius: 30px;
  padding: 30px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 625px;
  width: 100%;
  & h4 {
    @include desktop_h4;
    margin-top: 20px;
  }

  & p {
    @include text_medium_regular;
    color: $textdark;
    margin-top: 20px;
  }
}
