@import "../../scss/main.scss";

.wrapper {
  z-index: 99999999999999;
  display: flex;
  align-items: flex-end;

  width: 100%;
  position: fixed;
  top: 0px; /* adding px unit also seems to be important for whatever reason, albeit I think we all concur that this should be unitless */
  left: 0px;
  bottom: 0px;
  height: 100vh;
  overflow: scroll; /* not auto! */

  /* magic mobile viewport iOS bug fix */
  /* also see: https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/ */
  /* also see: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
  height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
}

.contentContainer {
  max-height: 75vh;
  overflow-y: scroll;
  padding: 20px 16px 20px 16px;
  background: $grayF8;
  width: 100%;
  border-radius: 30px 30px 0px 0px;
  gap: 10px;
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;

  & .fasting {
    width: 30px;
    height: 30px;
  }

  &Disable {
    overflow: initial;
    & > div:not(:first-child) {
      pointer-events: none;
    }
  }
}

.tooltip {
  right: 50%;
  top: 0;
  transform: translate(50%, -97%);

  &::after {
    right: 50%;
    rotate: 270deg;
    bottom: -17px;
    transform: translateX(50%);
  }
}
