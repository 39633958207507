@import "../../scss/main.scss";
.wrapper {
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;

  @media (max-width: 768px) {
    margin: 20px auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  & .contentContainer {
    max-width: 946px;
    border-radius: 30px;
    padding: 30px;
    background: $white;

    @media (max-width: 768px) {
      max-width: 768px;
      width: 100%;
      padding: 20px;
      border-radius: 20px;
    }
  }

  & h3 {
    @include desktop_h3;
    margin-top: 30px;

    @media (max-width: 768px) {
      @include mobile_h3;
      margin-bottom: 20px;
    }
  }

  & .subtitle {
    @include text_medium_regular;
    margin-top: 15px;
    color: $textdark;

    @media (max-width: 768px) {
      @include text_small_regular;
      margin-top: 10px;
    }
  }

  & .preview {
    width: 100%;
    height: auto;
    border-radius: 20px;
    margin-top: 30px;
    // max-height: 460px;
    object-fit: cover;
    background-color: #f8f8f8;
  }

  & .html_wrapper {
    font-weight: 500;
  }

  & .description {
    @include text_medium_medium;
    // line-height: 40px;
    color: $textblack;
    margin-top: 30px;

    @media (max-width: 768px) {
      @include text_small_medium;
      margin-top: 20px;
    }

    & ul {
      list-style-type: disc;
      padding-left: 17px;

      li {
        list-style-type: inherit;
      }
    }

    & img {
      width: auto;
      max-width: 100%;
    }

    a {
      @include text_medium_medium;
      text-decoration: none;
      font-weight: 500;
      color: $primary;

      @media (max-width: 768px) {
        @include text_small_medium;
      }
    }
  }

  & .btnWrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .timerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-around;
    margin-top: 30px;
  }

  & .receipesBtnWrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      margin-top: 20px;
      flex-direction: column;
      gap: 15px;
    }
  }
}

.stepContent {
  position: relative;

  & .stepText {
    @include text_medium_regular;
    color: $gray3A;
  }
}

.blurWrapper {
  filter: blur(6px);
  user-select: none;
}

.blockIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  color: $primary;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}
