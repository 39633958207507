@import "../../scss/main.scss";

.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: -webkit-fill-available;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  z-index: 999999999;
  background: $grayF8;
  padding: 20px;
  padding-bottom: 150px;
  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    & h4 {
      @include mobile_h4;
    }
  }

  & .list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
  }
}

.daysListItem {
  position: relative;

  &Active {
    z-index: 999991;

    & > div:first-of-type {
      pointer-events: none;
    }
  }

  &Tooltip {
    left: 50%;
    top: initial;
    bottom: 0;
    transform: translate(-50%, 97%);
    &::after {
      left: initial;
      right: 50%;
      rotate: 90deg;
      top: -16px;
      transform: translateX(50%);
    }
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999991;
  background-color: rgba(19, 19, 19, 0.6);
}
