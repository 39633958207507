@import "../../scss/main.scss";

.wrapper {
  & .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include mobile_h4;
    }

    & .navigation {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      & .navBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 4px 9px;
        border: 2px solid $grayE4;
        @include text_small_bold;
        color: $textblack;
      }

      & .activeNavBtn {
        border: 2px solid transparent;
        background: $primary;
        color: $white;
      }
    }
  }

  & .tackingList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
  }
}
