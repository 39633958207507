@import "../../scss/main";

.wrapper {
  width: 377px;
  padding: 16px;
  border-radius: 30px;
  background: $white;
  box-shadow: 0px 50px 90px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 80px;
  left: -16px;

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
  }

  & h5 {
    @include desktop_h5;
    text-align: center;
    @media (max-width: 768px) {
      @include mobile_h5;
    }
  }
}
