@import "../../scss/main.scss";

.menuBtn {
  width: 56px;
  height: 56px;
  background: $white;
  box-shadow: 0px 11px 50px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .menuCounter {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $error;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    @include text_x_small_bold;
    color: $white;
    position: absolute;

    left: -6px;
    top: -6px;
  }
}
