@import "../../scss/main.scss";

.container {
  width: 625px;
  margin: 30px auto;
  padding: 30px;
  background: $white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px auto;
    padding: 14px;
  }
}

.navigationBtnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    justify-content: space-between;
  }

  & a {
    @include inactiveNavLink;
    &[class*="active"] {
      @include activeNavLink;
    }
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }

  & input {
    width: 272px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

input {
  width: 100%;
}

.privacyWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 17px;

  & input {
    width: 24px;
    height: 24px;

    &:checked {
      border: 1px solid $primary;
    }
  }

  & p {
    @include text_small_regular;
    color: $gray3A;
    margin-left: 10px;

    & a {
      text-decoration: none;
      color: $primary;
    }
  }
}

.error {
  @include text_small_regular;
  color: $error;
  text-align: center;
}
