@import "src/scss/main";

.inputContainer {
  position: relative;
}

.label {
  @include text_small_bold;
  margin-bottom: 5px;
}

.input {
  @include text_small_regular;
  outline: none;
  padding: 13px 20px;
  border: none;
}

.inputWrapper {
  border: 1px solid $grayE4;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  &:hover {
    cursor: text;
  }
}

.inputWrapperFocus {
  border: 1px solid $primary;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  &:hover {
    cursor: text;
  }
}

.inputWrapperError {
  border: 1px solid $error;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: text;
  }
}

.errorText {
  position: absolute;
  @include text_x_small_regular;
  color: $error;
  right: 3.8px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 16px;
  cursor: pointer;

  & svg {
    color: $grayAf;
  }
}
