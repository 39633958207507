@import "../../scss/main.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  max-width: 440px;
  height: 136px;
  background-color: #eee3fb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 999999999999999999;
  border-radius: 20px;
  padding: 30px;
}

.title {
  @include desktop_h4;
  font-size: 18px;
}
