@import "../../scss/main.scss";

$mobileAdapriveWidth: 950px;

.wrapper {
  border-radius: 30px;
  background: $white;
  padding: 30px;
  margin-bottom: 40px;
  width: 407px;
  position: relative;

  @media (max-width: $mobileAdapriveWidth) {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 5px;
  }

  & .title {
    @include desktop_h4;

    @media (max-width: $mobileAdapriveWidth) {
      @include mobile_h4;
    }
  }

  & .remindersList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  & .reminderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    & svg {
      color: $primary;
    }

    & .reminder {
      @include text_medium_regular;
      max-width: 80%;
      margin-left: 10px;
      word-wrap: break-word;
    }
  }

  & h5 {
    @include desktop_h5;

    & span {
      @include desktop_h4;
      color: $primary;
    }
  }
}

.blurWrapper {
  filter: blur(6px);
  user-select: none;
}

.blockIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  color: $primary;
}
