@import "../../scss/main.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 36px;
  height: 36px;
  background: $primaryOpacity;
  box-shadow: 0px 11px 50px rgba(0, 0, 0, 0.07);
  @include desktop_h5;
  color: $primary;
  @media (max-width: 768px) {
    @include mobile_h5;
    line-height: 25px;
  }
  cursor: pointer;
}

.complete {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 36px;
  height: 36px;
  background: $primary;
  box-shadow: 0px 11px 50px rgba(0, 0, 0, 0.07);
  @include desktop_h5;
  cursor: pointer;

  @media (max-width: 768px) {
    @include mobile_h5;
    line-height: 25px;
  }

  & svg {
    color: $white;
  }
}

.activeStepWrapper {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primaryOpacity;
  border-radius: 100px;
  cursor: pointer;

  & .activeStep {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 36px;
    height: 36px;
    background: $primary;
    box-shadow: 0px 11px 50px rgba(0, 0, 0, 0.07);
    @include desktop_h5;
    color: $white;
    @media (max-width: 768px) {
      @include mobile_h5;
      line-height: 25px;
    }
  }
}
.divider {
  flex-grow: 1;
  border-top: 3px dashed $primaryOpacity;
  height: 3px;
}
.activeDivider {
  flex-grow: 1;
  border-top: 3px solid $primary;
  height: 3px;
}
