@import "../../scss/main.scss";

.html-wrapper a.print_videoLink {
  display: none;
}

.print_ {
  &headerImg {
    display: none;
  }
  &headerTitle {
    display: none;
  }
}

@media print {
  .html-wrapper a.print_videoLink {
    display: initial;
    cursor: pointer;
    color: $primary;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
  }
  .print_ {
    &contentContainer {
      padding: 5px 30px;
    }

    &shortOverview {
      padding-bottom: 15px;
      overflow-wrap: break-word;
    }
    &sectionTitle {
      font-weight: 800;
      font-size: 24px;
      line-height: 28px;
      margin-top: 20px;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        @include mobile_h4;
      }
    }

    &ingredientItem {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      &Link {
        cursor: pointer;
        color: $primary;
      }

      & span {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        margin-left: 5px;
        font-family: $secondary-font;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    &amountWrapper {
      overflow: hidden;
      width: auto;
      min-width: 136px;
      padding: 3px 10px;
      background: $grayF8;
      border-radius: 10px;
      border-width: 1px solid black;
      display: flex;
      justify-content: flex-end;

      @media (max-width: 768px) {
        min-width: 114px;
      }
      & p {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        font-family: $secondary-font;
        flex: initial;

        @media (max-width: 768px) {
          @include text_small_bold;
        }

        & span {
          color: #3a3a3a;
          margin: 0 5px;
          @media (max-width: 768px) {
            @include text_small_bold;
          }
        }
      }
    }

    &tagsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      border-top: 1px solid $grayE4;
      padding-top: 20px;
      padding-bottom: 25px;
    }
    &tag {
      padding: 7px 13px;
      background: rgba(140, 66, 227, 0.15);
      border-radius: 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      font-family: $secondary-font;
      color: $primary;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 22px;
        padding: 5px 10px;
      }
    }
    &headerImg {
      display: block;
      height: auto;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 3/2;
      border-radius: 10px;
    }
    &headerTitle {
      display: block;
      overflow: hidden;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 20px;
      overflow-wrap: break-word;
      margin-top: 20px;
    }
  }
}
